import { Stack, StackItem } from '../../pages/common';
import { animated, useSpring } from '@react-spring/web';

import styles from './Answer.module.css';

export const AnswerLoading = () => {
  const animatedStyles = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <animated.div style={{ ...animatedStyles }}>
      <Stack className={styles.answerContainer}>
        <StackItem grow>
          <p className={styles.answerText}>
            Generating answer
            <span className={styles.loadingdots} />
          </p>
        </StackItem>
      </Stack>
    </animated.div>
  );
};
