import { useEffect } from 'react';
import { ChatItem } from '../../store/types';

const getPosition = (chatMessageDiv: HTMLDivElement, questionInputDiv: HTMLDivElement) =>
  window.scrollY +
  chatMessageDiv.getBoundingClientRect().bottom -
  questionInputDiv.getBoundingClientRect().top;

export const useScrollToMessage = (
  isLoading: boolean,
  streamedAnswers: ChatItem[],
  chatMessageDiv: HTMLDivElement | null,
  questionInputDiv: HTMLDivElement | null
) => {
  useEffect(() => {
    if (!chatMessageDiv || !questionInputDiv) {
      return;
    }

    window.scrollTo({
      top: getPosition(chatMessageDiv, questionInputDiv),
      behavior: 'smooth',
    });
  }, [isLoading, chatMessageDiv, questionInputDiv]);

  useEffect(() => {
    if (!chatMessageDiv || !questionInputDiv) {
      return;
    }

    window.scrollTo({
      top: getPosition(chatMessageDiv, questionInputDiv),
      behavior: 'auto',
    });
  }, [streamedAnswers, chatMessageDiv, questionInputDiv]);
};
