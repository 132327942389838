import { useState } from 'react';
import { selectChatItems, selectIsLoading } from '../../store/appSlice';
import { useScrollToMessage } from './hooks';
import { useAppSelector } from '../../store/hooks';
import { ChatAnswerType } from '../../store/types';
import { Answer, AnswerError, AnswerLoading } from '../Answer';
import { UserChatMessage } from '../UserChatMessage';
import { ChatMessageStreamProps } from './types';

import styles from './ChatMessageStream.module.css';

export const ChatMessageStream = ({ makeApiRequest, questionInputRef }: ChatMessageStreamProps) => {
  const isLoading = useAppSelector(selectIsLoading);
  const chatItems = useAppSelector(selectChatItems);

  const [chatMessageRef, setChatMessageRef] = useState<HTMLDivElement | null>(null);
  useScrollToMessage(isLoading, chatItems, chatMessageRef, questionInputRef.current);

  return (
    <div className={styles.chatMessageStream}>
      {chatItems.map((chatItem) => (
        <div
          key={chatItem.id}
          ref={
            chatItem.answer.type === ChatAnswerType.streaming ||
            chatItem.answer.type === ChatAnswerType.loading
              ? setChatMessageRef
              : null
          }
        >
          <UserChatMessage message={chatItem.question} />
          {chatItem.answer.type !== ChatAnswerType.error &&
            chatItem.answer.type !== ChatAnswerType.loading && (
              <div className={styles.chatMessageGpt}>
                <Answer key={chatItem.id} answer={chatItem.answer} />
              </div>
            )}
          {chatItem.answer.type === ChatAnswerType.loading && (
            <div className={styles.chatMessageGptMinWidth}>
              <AnswerLoading />
            </div>
          )}
          {chatItem.answer.type === ChatAnswerType.error && (
            <div className={styles.chatMessageGptMinWidth}>
              <AnswerError
                message={chatItem.answer.content}
                disabled={isLoading}
                onRetry={() => makeApiRequest(chatItem.question, chatItem.id)}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
