export const allowedRedirectProtocol = 'https:';

export const redirect = (url: string, allowedOrigin: string): boolean => {
  try {
    const parsedURL = new URL(protectUrlAgainstXSS(url));

    if (parsedURL.protocol !== allowedRedirectProtocol) {
      return false;
    }

    if (!isOriginAllowed(url, allowedOrigin)) {
      return false;
    }

    window.location.href = ensureValidAmpersands(parsedURL.toString());
    return true;
  } catch {
    return false;
  }
};

const protectUrlAgainstXSS = (text?: string) => {
  const temp = document.createElement('div');
  temp.textContent = text || null;
  return temp.innerHTML;
};

const isOriginAllowed = (url: string, allowedOrigin: string) => {
  if (!allowedOrigin || !url) {
    return false;
  }

  try {
    const parsedUrl = new URL(url);
    const urlOrigin = parsedUrl.origin;

    return urlOrigin.toLowerCase() === allowedOrigin.toLowerCase();
  } catch {
    return false;
  }
};

const ensureValidAmpersands = (text: string) => text.replace('&amp;', '&');
