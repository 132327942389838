import { PropsWithChildren } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';

import styles from './ErrorPage.module.css';

export const ErrorPage = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles.container} role="alert">
      <div className={styles.icon}>
        <FaExclamationCircle />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
