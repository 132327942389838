import { MouseEventHandler, PropsWithChildren } from 'react';

import styles from './Button.module.css';

interface ButtonProps {
  className?: string;
  icon?: JSX.Element;
  large?: boolean;
  disabled?: boolean;
  onClick: MouseEventHandler;
}

export const Button = ({
  className,
  icon,
  large = false,
  disabled = false,
  onClick,
  children,
}: PropsWithChildren<ButtonProps>) => {
  return (
    <button
      className={`${className ? className + ' ' : ''}${styles.button}`}
      disabled={disabled}
      onClick={onClick}
    >
      {!!icon && (
        <span
          className={`${styles.buttonIcon} ${large ? styles.buttonIconLarge : styles.buttonIconNormal}`}
        >
          {icon}
        </span>
      )}
      {children}
    </button>
  );
};
