import { ExampleList } from '../Example';
import { EmptyChatProps } from './types';
import styles from './EmptyChat.module.css';

export const EmptyChat = ({ makeApiRequest }: EmptyChatProps) => {
  return (
    <div className={styles.chatEmptyState}>
      <h1 className={styles.chatEmptyStateTitle}>Chat with Synergi Life AI</h1>
      <ExampleList onExampleClicked={makeApiRequest} />
    </div>
  );
};
