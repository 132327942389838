import { CaseDraft } from '../store/types';

export type ChatRequestMessage = {
  content: string;
  role: string;
};

export type ChatRequest = {
  messages: ChatRequestMessage[];
  draft?: CaseDraft;
};

// Each ChatResponse... type represents one of the types that can be returned from /chat endpoint.
// It's important to keep them in sync with changes in API code.

export interface ChatResponseContentStream {
  content: string;
}

export interface ChatResponseDraft {
  case: CaseDraft;
}

export interface ChatResponseCase {
  caseCreationStatus: CaseCreationStatus;
  caseId?: number;
}

export enum CaseCreationStatus {
  created = 'created',
  failed = 'failed',
}

export type ChatResponseRawText = string;

export interface ChatResponseGenericError {
  error: {
    status: number;
    message: string;
  };
}

export interface ChatResponseInvalidRequest {
  errors: { type: string }[];
}

export type ChatResponse = ChatResponseContentStream &
  ChatResponseDraft &
  ChatResponseCase &
  ChatResponseRawText &
  ChatResponseGenericError &
  ChatResponseInvalidRequest;
