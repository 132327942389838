import { Example } from './Example';
import { FaLock, FaUserCircle } from 'react-icons/fa';
import { RiQuestionnaireFill } from 'react-icons/ri';
import styles from './Example.module.css';

const DEFAULT_EXAMPLES = [
  {
    question: 'What example questions related to Synergi Life I can ask you?',
    icon: RiQuestionnaireFill,
    iconColor: '#B56700',
    iconSize: 24,
  },
  {
    question: 'How to change my password in Synergi Life?',
    icon: FaLock,
    iconColor: '#009FDA',
    iconSize: 20,
  },
  { question: 'How can I create a user?', icon: FaUserCircle, iconColor: '#A1AAE6', iconSize: 22 },
];

interface Props {
  onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
  return (
    <ul className={styles.examplesNavList}>
      {DEFAULT_EXAMPLES.map((example, i) => (
        <li key={i}>
          <Example
            icon={<example.icon size={example.iconSize} color={example.iconColor} />}
            text={example.question}
            value={example.question}
            onClick={onExampleClicked}
          />
        </li>
      ))}
    </ul>
  );
};
