import { PrimaryButton, Stack, StackItem } from '../../pages/common';
import { MdErrorOutline } from 'react-icons/md';

import styles from './Answer.module.css';

interface Props {
  message: string;
  disabled: boolean;
  onRetry: () => void;
}

export const AnswerError = ({ message, disabled, onRetry }: Props) => {
  return (
    <Stack className={styles.answerContainer}>
      <StackItem grow className={styles.errorContainer}>
        <MdErrorOutline size={20} aria-hidden="true" aria-label="Error icon" color="#C4262E" />{' '}
        <span className={`${styles.answerText} ${styles.answerTextError}`}>{message}</span>
      </StackItem>

      <PrimaryButton
        className={styles.retryButton}
        disabled={disabled}
        onClick={onRetry}
        text="Retry"
      />
    </Stack>
  );
};
