import { AuthProviderProps } from 'react-oidc-context';
import applicationSettings from '../infrastructure/config';
import { WebStorageStateStore } from 'oidc-client-ts';

export const oidcConfig = (): AuthProviderProps => ({
  authority: applicationSettings.oidcServiceUrl,
  client_id: applicationSettings.oidcClientId,
  redirect_uri: applicationSettings.applicationUrl,
  post_logout_redirect_uri: applicationSettings.applicationUrl,
  scope: applicationSettings.oidcScope,
  acr_values:
    applicationSettings.oidcDefaultProviderId?.length > 0
      ? `idp:${applicationSettings.oidcDefaultProviderId}`
      : undefined,
  response_type: 'code',
  response_mode: 'query',
  monitorSession: false,
  automaticSilentRenew: false,
  filterProtocolClaims: true,
  stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  onSigninCallback: (): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
});
