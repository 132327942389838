export const formatJSONReadable = (json: object): string => {
  const formattedLines: string[] = ['\n'];

  for (const [key, value] of Object.entries(json)) {
    formattedLines.push(
      `**${formatKey(key)}**: ${typeof value === 'object' ? JSON.stringify(value) : formatDate(value)}`
    );
  }

  return formattedLines
    .join('\n')
    .replace(/</g, '&lt;') // Replace all < with &lt;
    .replace(/>/g, '&gt;') // Replace all > with &gt;
    .replace(/\*\*([^*\n]+)\*\*/g, '<strong>$1</strong>')
    .replace(/\*([^*\n]+)\*/g, '<em>$1</em>');
};

const formatKey = (key: string): string =>
  key
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

const formatDate = (value: string): string => {
  const date = new Date(value);
  return isNaN(date.getTime())
    ? value
    : `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`;
};
