import styles from './Example.module.css';

interface Props {
  icon: JSX.Element;
  text: string;
  value: string;
  onClick: (value: string) => void;
}

export const Example = ({ icon, text, value, onClick }: Props) => {
  return (
    <div className={styles.example} onClick={() => onClick(value)}>
      {icon}
      <span className={styles.exampleText}>{text}</span>
    </div>
  );
};
