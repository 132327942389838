import { PropsWithChildren } from 'react';

import styles from './Button.module.css';

interface LinkButtonProps {
  className?: string;
  href: string;
  icon?: JSX.Element;
  disabled?: boolean;
}

export const LinkButton = ({
  className,
  href,
  icon,
  disabled = false,
  children,
}: PropsWithChildren<LinkButtonProps>) => {
  return (
    <a
      className={`${className ? className + ' ' : ''}${styles.button}${disabled ? ' ' + styles.buttonDisabled : ''}`}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {!!icon && <span className={`${styles.buttonIcon} ${styles.buttonIconNormal}`}>{icon}</span>}
      {children}
    </a>
  );
};
