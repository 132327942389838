import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';
import './index.css';
import { store } from './store/store';
import { oidcConfig } from './auth/constants';
import { router } from './router';
import { fetchApplicationSettings } from './infrastructure/config';

async function bootstrap() {
  await fetchApplicationSettings();
}

async function renderRoot() {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <AuthProvider {...oidcConfig()}>
      <React.StrictMode>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </React.StrictMode>
    </AuthProvider>
  );
}

Promise.resolve().then(bootstrap).then(renderRoot).catch(console.error);
