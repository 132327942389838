import { MutableRefObject, useEffect, useState } from 'react';
import { Button, Stack, TextField } from '../../pages/common';
import { MdSend } from 'react-icons/md';

import styles from './QuestionInput.module.css';
import { inputCharactersLimitText } from './constants';

interface Props {
  onSend: (question: string) => void;
  disabled: boolean;
  initQuestion?: string;
  placeholder: string;
  clearOnSend: boolean;
  questionInputRef: MutableRefObject<HTMLDivElement | null>;
}

export const QuestionInput = ({
  onSend,
  disabled,
  placeholder,
  clearOnSend,
  initQuestion,
  questionInputRef,
}: Props) => {
  const [question, setQuestion] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    initQuestion && setQuestion(initQuestion);
  }, [initQuestion]);

  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      return;
    }

    onSend(question);
    setErrorMessage(undefined);

    if (clearOnSend) {
      setQuestion('');
    }
  };

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === 'Enter' && !ev.shiftKey) {
      ev.preventDefault();
      sendQuestion();
    }
  };

  const resetErrorMessage = () => (errorMessage ? setErrorMessage(undefined) : undefined);

  const onQuestionChange = (
    _ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (!newValue) {
      setQuestion('');
      resetErrorMessage();
    } else if (newValue.length < 1000) {
      setQuestion(newValue);
      resetErrorMessage();
    } else if (newValue.length >= 1000) {
      setErrorMessage(inputCharactersLimitText);
    }
  };

  const isSendingQuestionDisabled = disabled || !question.trim();

  return (
    <div ref={questionInputRef} className={styles.questionInputBackground}>
      <div className={styles.questionInputContainer}>
        <Stack horizontal className={styles.questionInputInnerBox}>
          <TextField
            className={styles.questionInputTextArea}
            placeholder={placeholder}
            value={question}
            onChange={onQuestionChange}
            onKeyDown={onEnterPress}
          />
          <div className={styles.questionInputButtonsContainer}>
            <Button
              className={`${styles.questionInputButton} ${isSendingQuestionDisabled ? styles.questionInputButtonDisabled : styles.questionInputButtonEnabled}`}
              large
              icon={<MdSend />}
              disabled={isSendingQuestionDisabled}
              onClick={sendQuestion}
            />
          </div>
        </Stack>
        {errorMessage ? (
          <div role="alert">
            <p className={styles.errorMessage}>
              <span data-automation-id="error-message">{errorMessage}</span>
            </p>
          </div>
        ) : null}
        <div className={styles.disclaimer}>
          <span data-automation-id="disclaimer">
            Synergi Life AI aims to be accurate but mistakes are possible. Be sure to verify the
            responses.
          </span>
        </div>
      </div>
    </div>
  );
};
