import { AuthContextProps } from 'react-oidc-context';
import { setIsLoading, updateChatItem } from '../store/appSlice';
import { AppDispatch } from '../store/store';
import { postChatApi } from './api';
import { handleResponse } from './response';
import { CaseDraft, ChatAnswerType, ChatItem } from '../store/types';
import { HttpStatus } from './http';
import { forbiddenErrorText, genericErrorText, unauthorizedErrorText } from './constants';
import { ChatRequest, ChatRequestMessage } from './types';

export const handleRequest = (
  dispatch: AppDispatch,
  chatItems: ChatItem[],
  auth: AuthContextProps
) => {
  return async (question: string, id?: number) => {
    const itemId = id ?? Date.now();
    const indexOfItem = chatItems.findIndex((item) => item.id === itemId);

    const dispatchUpdate = (content: string, type: ChatAnswerType) => {
      dispatch(
        updateChatItem({
          id: itemId,
          question,
          answer: { content, type },
        })
      );
    };

    dispatch(setIsLoading(true));
    dispatchUpdate('', ChatAnswerType.loading);

    try {
      // gets the slice of messages before current message
      const chatItemsBeforeIndex = indexOfItem === -1 ? chatItems : chatItems.slice(0, indexOfItem);
      const messages: ChatRequestMessage[] = chatItemsBeforeIndex
        .filter((item) => item.answer.type === ChatAnswerType.ok)
        .flatMap((item) => [
          { content: item.question, role: 'user' },
          {
            content: item.answer.content,
            role: 'assistant',
          },
        ]);

      // finds last not sent draft
      let draft: CaseDraft | undefined;

      for (const item of chatItemsBeforeIndex.toReversed()) {
        if (item.answer.draft) {
          draft = item.answer.draft;
          break;
        }
        if (item.answer.type === ChatAnswerType.caseCreated) {
          break;
        }
      }

      const request: ChatRequest = {
        messages: [...messages, { content: question, role: 'user' }],
        draft,
      };

      const response = await postChatApi(request, {
        accessToken: auth.user?.access_token,
      });

      if (!response.body) {
        dispatchUpdate(genericErrorText, ChatAnswerType.error);
        return;
      }

      if (response.status == HttpStatus.Unauthorized) {
        dispatchUpdate(unauthorizedErrorText, ChatAnswerType.unauthorized);
        return;
      }

      if (response.status == HttpStatus.Forbidden) {
        dispatchUpdate(forbiddenErrorText, ChatAnswerType.forbidden);
        return;
      }

      await handleResponse(dispatch)(response, itemId, question);
    } catch (e) {
      dispatchUpdate(genericErrorText, ChatAnswerType.error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
