import { PropsWithChildren } from 'react';

import styles from './StackItem.module.css';

interface StackItemProps {
  className?: string;
  grow?: boolean;
}

export const StackItem = ({
  className,
  grow = false,
  children,
}: PropsWithChildren<StackItemProps>) => {
  return (
    <div
      className={`${className ? className + ' ' : ''}${styles.stackItem} ${grow ? styles.stackItemGrow : styles.stackItemShrink}`}
    >
      {children}
    </div>
  );
};
