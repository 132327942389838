import { PropsWithChildren } from 'react';

import styles from './Stack.module.css';

interface StackProps {
  className?: string;
  horizontal?: boolean;
}

export const Stack = ({
  className,
  horizontal = false,
  children,
}: PropsWithChildren<StackProps>) => {
  return (
    <div
      className={`${className ? className + ' ' : ''}${styles.stack} ${horizontal ? styles.stackRow : styles.stackColumn}`}
    >
      {children}
    </div>
  );
};
