import styles from './TextField.module.css';

interface TextFieldProps {
  className?: string;
  placeholder: string;
  value: string;
  onChange: (_ev: React.FormEvent<HTMLTextAreaElement>, newValue?: string) => void;
  onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement>;
}

export const TextField = ({
  className,
  placeholder,
  value,
  onChange,
  onKeyDown,
}: TextFieldProps) => {
  return (
    <>
      <textarea
        className={`${className ? className + ' ' : ''}${styles.textField}`}
        placeholder={placeholder}
        value={value}
        onChange={(event) => {
          onChange(event, event.target.value);
        }}
        onKeyDown={onKeyDown}
      />
    </>
  );
};
