interface ApplicationSettings {
  applicationUrl: string;
  synergiLifeCaseUrl: string;
  oidcServiceUrl: string;
  oidcClientId: string;
  oidcScope: string;
  oidcDefaultProviderId: string;
  reportBugUrl: string;
}

const applicationSettings: Partial<ApplicationSettings> = {};

export async function fetchApplicationSettings(): Promise<void> {
  const response = await fetch('/api/clientauthconfig');
  if (!response.ok) {
    throw new Error('Failed to fetch application settings');
  }

  try {
    const fetchedSettings = (await response.json()) as Partial<ApplicationSettings>;
    Object.assign(applicationSettings, fetchedSettings);
  } catch {
    throw new Error('Failed to read application settings');
  }
}

export default applicationSettings as Readonly<ApplicationSettings>;
